.page-wrapper{
}
.sidebar-content{
    font-family: 'Noto Serif', serif;
    font-size: 1.1rem;
}
.page-content{
    font-family: 'Noto Serif', serif;
    font-size: 1.1rem;
}
.question-title {
    width: 100%;
    height: 40px;
    background-color: #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.question-index {
    width: 40px;
    height: 40px;
    background-color: #141414;
    font-weight: 900;
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.bookmark {
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;
}

.bookmark.marked
{
  font-weight: 700;
}

.cross-line
{
  position: absolute;
  width: calc(100% + 10px);
  border: solid #000 1px;
  left: -5px;
}

.crossing-zone
{
  width: 40px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  user-select: none;
  display: none;
}

.cross-label
{
  position: absolute;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #000 1px;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 600;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.cross-btn-line
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 30px;
  border: solid #000 1px;
  margin: 0;
}

.undo-cross
{
  text-decoration: underline;
  font-weight: 700;
  font-family: Roboto;
}

.crossing-options {
    margin-left: auto;
    height: 36px;
    box-sizing: border-box;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    user-select: none;
}
.bookmark-img,.crossing-options-img {
    width: auto;
    height: 100%;
    /*bookmark_filled.png crossAbcActive.png*/
}
.q-dash{
    height: 6px;
    background: url(./images/short.svg) no-repeat;
    background-position: 0 2px;
}
.question-content {
    margin-top:15px;
}
.question-content p {
    margin-bottom:12px;
}

.answer-zone {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.answer-preview
{
  margin-top: 40px;
  font-family: Minion Pro,serif;
  font-weight: 900;
  font-size: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.answer-preview .value,.answer-preview .value span
{
  display: flex;
  flex-direction: column;
  font-family: Minion Pro,serif;
  font-weight: 900;
  font-size: 28px;
  align-items: center;
  margin: 0;
}

.answer-preview .value span
{
  font-size: 20px;
}

.answer-preview .value hr
{
  width: 100%;
  margin: 0;
  border-top: solid 2px #000;
}

.option
{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.select-zone
{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 14px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #000;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
}

.select-zone.crossed .option-label,.select-zone.crossed .option-content
{
  opacity: .4;
}

.option input[type="radio"]{
    display: none;
}

.option label{
    margin-bottom: 0;
}
.option input[type="radio"]:checked + label {
    border: solid 3px #324dc7;
}
.option input[type="radio"]:checked + label div.option-label {
    background-color: #324dc7;
    color: #fff;
}

/*.select-zone:hover,.option.selected .select-zone
{
  background-color: #0077c8;
  border-color: #0077c8;
  color: #fff;
}*/

.option-label
{
  width: 26px;
  height: 26px;
  border: solid 2px #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  box-sizing: border-box;
  font-weight: 900;
  font-size: 13px;
  flex-shrink: 0;
}

/*.select-zone:hover .option-label,.option.selected .option-label
{
  border-color: #fff;
}*/
.option-content
{
  font-family: "Noto Serif",serif;
}

.input-wrapper
{
  border: solid #000 1px;
  border-radius: 10px;
  padding: 12px;
  width: 130px;
  box-sizing: border-box;
}

.answer-input
{
  font-size: 25px;
  padding: 2px;
  border: none;
  border-bottom: solid #000 1px;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 3px;
}

.answer-input:focus
{
  outline: none;
  border-bottom-width: 2px;
}

.question-title .bookmark{
    background-image: url("./images/bookmark_empty.png");
    background-size: contain; /* 背景图像等比例缩放，尽量填充容器 */
    background-repeat: no-repeat; /* 防止背景图像重复 */
    padding-left: 36px;
    font-family: Roboto;
}
.question-title .bookmark.filled{
    background-image: url("./images/bookmark_filled.png");
    font-weight: 600;
}
.question-title .crossing-options{
    background-image: url("./images/crossAbc.png");
    background-size: contain; /* 背景图像等比例缩放，尽量填充容器 */
    background-repeat: no-repeat; /* 防止背景图像重复 */
    padding-left: 36px;
}
.question-title .crossing-options.filled{
    /* background-image: url("./images/crossAbcActive.png"); */
}
.single-detail .bi-bookmark-fill{font-size: 1.3rem; color: #c13145;}
.single-question .bi-bookmark-fill{font-size: 0.8rem; color: #c13145;}

.resizer-img {
    width: 25px;
    height: auto;
}
.resizer-img.right {
    transform: scaleX(-1);
}
.resizer {
    position: absolute;
    top: 20px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: #000000b3 0 4px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    opacity: .6;
    z-index: 1000;
    margin-bottom: 30px;
}
.sidebar-wrapper.large{
    width:66%;
}
.sidebar-wrapper.small{
    width:34%;
}
.page-content-wrapper.large{
    width:66%;
}
.page-content-wrapper.small{
    width:34%;
}
.sidebar-wrapper.large .resizer-img{
    transform: rotate(180deg);
}
.page-content-wrapper.large .resizer-img{
    transform: rotate(90deg);
}
.sidebar-content{
    margin-top:45px;
}
.page-content{
}
.resize{
    position: relative;
    top: 0px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: #000000b3 0 4px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    opacity: .5;
    z-index: 1000;
}
.resize.left {
    float: right;
    right: 6px;
}
.resize.right {
    left: 6px;
}
.resize:hover{
    opacity:.8
}

.page-wrapper2 {
    width: 100%;
    position: relative;
    /*height: calc(100% - 170px);
    position: absolute;
    top: 80px;*/

}


/*留在当前页面*/
.mycontainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
}
.minimap{display:block}

.title {
    margin-bottom: 20px;
    font-size: 2.2rem;
    font-weight: 500;
}
.desc {
    font-size: 17px;
    margin-top: 10px;
}
.minimap2 {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    padding: 37px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: #0003 0 3px 16px;
    width: calc(55%);
    min-width: 600px;
}
.section-title {
    font-size: 22px;
    font-weight: 700;
}
.icon-detail2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    box-sizing: border-box;
    flex-shrink: 0;
}
.single-detail {
    gap: 4px;
    font-size: 1rem;
    font-weight: 500;
}
.section-info{gap:30px}
.question-list{column-gap:35px;row-gap:25px;}
.single-question{width:48px}
.question-num{height:48px}

.marked {
    left: 40px;
    bottom: 40px;
}

.my-wrapper {
    width: 100%;
    height: calc(100% - 195px);
    position: fixed;
    left: 0px;
    z-index: 2200;
    border-right: 5px solid #CCC;
    /* border: 3px solid #ff0000; */
    /* box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,.13); */
    transition: all .3s ease-out;
}