.rich-text-content img {
    max-width: 100%; /* Ensures image is not wider than its container */
    height: auto; /* Maintains aspect ratio */
    display: block; /* Display as block to center the image */
    margin: 0 auto; /* Center the image horizontally */
    max-height: 35vh; /* Set a maximum height for all images */
}
.rich-text-content2 img {
    max-width: 100%; /* Ensures image is not wider than its container */
    height: auto; /* Maintains aspect ratio */
    display: block; /* Display as block to center the image */
    margin: 0 auto; /* Center the image horizontally */
    max-height: 55vh; /* Set a maximum height for all images */
}

  
  .rich-text-content {
    overflow: hidden;
  }
  
  .question-hover-action {
    position: relative;
  }
  
  .question-hover-action .delete-question-btn {
    visibility: hidden;

  }
  .action-buttons-container {
    position: absolute;
    right: 10px; /* Adjust based on your layout */
    top: 10px; /* Adjust based on your layout */
    opacity: 0; /* Start fully transparent */
    transition: opacity 0.3s ease-in-out;
    visibility: hidden; /* Initially hidden */
  }

  .question-hover-action:hover .action-buttons-container {
    visibility: visible;
    opacity: 1; /* Start fully transparent */


  }
  
  .question-hover-action:hover .delete-question-btn {
    visibility: visible;
    opacity: 1; /* Start fully transparent */
  }
  