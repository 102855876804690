@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@300;400&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
html, body, #app {
font-family: Roboto;
position: relative;
width: 100%;
height: 100%;
padding: 0;
margin: 0;
}
.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full viewport height */
    background-color: #1e1e1e; /* Set your desired background color here */
    color: #fff; /* Set the text color to white */
    width: 100%;
    margin: 0;
    padding: 0;
  }
.break {
    width: 100%;
    height: 92%;
    background-color: #1e1e1e;
    color: #fff;
    display: flex;
    flex-direction: row;
    font-size: 20px;
}
.footer{
    background-color:#1e1e1e;
    height:8%;
    color:#fff;
    padding-left: 36px;
    font-size: 1.8rem;
}
.left-side {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
}
.timer {
    padding: 15px 45px;
    border: solid #b1b1b1 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    border-radius: 10px;
}
.timer-time {
    font-size: 5.2rem;
}
.resume-btn {
    padding: 20px;
    background-color: #fedb00;
    color: #000;
    user-select: none;
    cursor: pointer;
    border-radius: 60px;
    font-weight: 900;
    font-size: 18px;
    transition: transform .2s 0s ease;
}
.right-side {
    width: 50%;
    padding-right:20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.contents {
    width: calc(100% - 50px);
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    gap: 25px;
    letter-spacing: .3px;
    line-height: 130%;
    font-weight: 300;
}
.desc-title {
    font-size: 34px;
    font-weight: 700;
}
hr {
width: 100%;
height: 1.5px;/* 设置高度为2像素 */
background-color: white; /* 设置背景颜色为黑色 */
border: none; /* 去掉默认边框 */
}