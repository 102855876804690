@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@300;400&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
html {
    width: 100%;
    height: 100%
}

body {
    /*	font-family: 'Open Sans',sans-serif;
	font-family: 'Roboto',sans-serif;*/
    font-family: Roboto, -apple-system, BlinkMacSystemFont, PingFang SC, Helvetica Neue, STHeiti, Microsoft Yahei, Tahoma, Simsun, sans-serif;
    background-color: #fff;
    letter-spacing: .1px;
    font-size: 14px
}

h1 {
    font-size: 1.5rem
}

h2 {
    font-size: 1.3rem
}

h3 {
    font-size: 1.1rem
}

.normal {
    font-weight: normal;
}

.super {
    font-size: 75%;
    vertical-align: super;
}

.suber {
    font-size: 75%;
    vertical-align: sub;
}

.upper-line {
    text-decoration: none;
    border-top: 1px solid #333;
    padding-top: 0;
    border-bottom: none;
    padding-bottom: 3px
}

a:hover {
    text-decoration: none
}

.wrapper {
    width: 100%;
    height: 100%;
    position: relative
}

.top-header {
    width: 100%;
    height: 90px;
    position: relative;
    font-family: Roboto;
    /*	height: 60px;
	line-height: 60px;
	background: #001733;
	position: fixed;
	top: 0;*/
    /*	box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);*/
    background: url('./images/long.svg') no-repeat;
    background-position: 0 85px;
    padding: 10px;
    /*    background-color: #fbfbfb;*/
    box-sizing: border-box;
    user-select: none;
    z-index: 2221;
}

.mymodal-open {
    background-color: #fbfbfb;
    z-index: 2400
}

.top-header .navbar {
    padding: 0;
    background: transparent;
}

/*
.left-topbar {
	float: left;
	padding-left: 18px;
	background: transparent
}*/

.left-topbar {
    flex-direction: column;
    gap: 8px;
    padding-left: 25px;
    align-items: flex-start;
}

.header-title {
    font-size: 22px;
    font-weight: 600;
}

.header-title.test-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.direction {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.header-desc {
    font-size: 14.5px;
    font-weight: 600;
}

.arrow {
    width: 10px;
    height: 10px;
    border: solid 2px;
    border-color: transparent transparent #000 #000;
    transform: rotate(-45deg) translate(2px, -2px);
    transition: transform .2s 0s ease;
}

.arrow.top {
    transform: rotate(135deg) translate(3px, -3px);
}

#directionmodal {
    z-index: 2450;
}

#directionmodal .modal-dialog {
    max-width: 100%;
    margin: 0;
}

.direction-container {
    --margin-value: 50px;
    position: fixed;
    width: calc(70% - var(--margin-value) * 2);
    height: calc(93% - 140px - var(--margin-value) * 2);
    margin: var(--margin-value);
    top: 40px;
    background-color: #fff;
    box-shadow: #00000080 0 4px 30px;
}

.direction-edge {
    background-color: #fff;
    width: 70px;
    height: 70px;
    position: absolute;
    left: 25px;
    top: -5px;
    transform: rotate(45deg);
    z-index: 2;
}

.direction-content {
    position: absolute;
    z-index: 3;
    padding: 45px 40px;
    box-sizing: border-box;
    font-size: 1.1rem;
    width: 100%;
    height: calc(100% - 90px);
    overflow: auto;
    font-family: 'Noto Serif', serif;
}

.direction-content .example {
    text-align: center;
}

.direction-content .table {
    width: 60%;
    margin: 0 auto !important;
}

.direction-content p {
    margin-bottom: .6rem;
}

.direction-content ul {
    padding-inline-start: 60px;
}

.smallgray {
    font-size: .9rem;
    background-color: #f0f0f0;
}

table.table-bordered {
    border: 1px solid #000;
    margin-top: 20px;
}

table.table-bordered>thead>tr>th {
    border: 1px solid #000;
    color: #000;
}

table.table-bordered>tbody>tr>td {
    border: 1px solid #000;
    color: #000;
}

.direction-close {
    position: absolute;
    right: 30px;
    bottom: 20px;
}

.mid-topbar {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.timer {
    font-size: 24px;
    font-weight: 600;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#hidetimer {
    padding: 2px 12px;
    border-radius: 23px;
    border: 1px solid #000;
    box-sizing: border-box;
    /*display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s 0s ease;*/
}

#hidetimer:hover {
    border: 2px solid #000;
    background-color: #F0F0F0;
    padding: 1px 11px
}

#timer {
    display: block
}

#timerx {
    display: none
}

.right-topbar {
    text-align: right;
    padding-right: 25px;
    gap: 8px;
}

.tools {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
}

.single-tool {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    user-select: none;
    cursor: pointer;
    position: relative;
}

.tool-icon {
    width: 25px;
    height: 25px;
}

.tool-icon img {
    width: auto;
    height: 100%;
}

/*
.sidebar-header {
	width: auto;
	display: flex;
	align-items: center;
	padding: 7px 10px;
	border-bottom: 1px solid #ededed
}*/

.right-topbar .navbar-nav .nav-link {
    padding: 0rem 1.4rem;
    border-left: 1px solid #ededed;
    color: #404142;
    height: 60px;
    font-size: 25px;
}

.ctrl-btns-container {
    position: absolute;
    right: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px
}

/*.ctrl-btn {
	padding:0 23px;
	background-color:#0077c8;
	height:46px;
	border-radius:23px;
	box-sizing:border-box;
	display:flex;
	align-items:center;
	justify-content:center;
	color:#fff;
	font-weight:700;
	cursor:initial;
	user-select:none;
	opacity:.4;
	pointer-events:none;
	transition:all .2s 0s ease
}
.ctrl-btn.enable {
	pointer-events:all;
	opacity:1;
	cursor:pointer
}
.ctrl-btn.enable:hover {
	transform:translateY(-3px);
	box-shadow:#00000080 0 3px 6px
}*/

.ctrl-btn {
    padding: 2px 23px;
    background-color: #324dc7;
    height: 46px;
    width: 86px;
    font-size: 1rem;
    border-radius: 23px;
    border: 1px solid transparent;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    cursor: initial;
    user-select: none;
    opacity: 1;
    cursor: pointer;
    pointer-events: all;
    transition: all .2s 0s ease;
}

.ctrl-btn.disable {
    opacity: .4;
    pointer-events: none;
}

.ctrl-btn:active {
}

/*
.ctrl-btn.enable:hover {
	transform:translateY(-3px);
	box-shadow:#00000080 0 3px 6px
}*/

.ctrl-yellow {
    background-color: #FDDB00;
    border: 1px solid #000;
    color: #000;
}

.ctrl-yellow:hover {
    border: 2px solid #000;
    padding: 0 21px
}

.ctrl-yellow:active {
    text-decoration: none;
}

#my-calculator,
#my-reference {
    z-index: 2388;
    max-width: 1000px;
    position: fixed;
    top: 95px;
    left: 100px;
    border: 1px solid #000;
    box-shadow: #0006 0 0 5px;
    font-size: 14px;
    display: none;
    background-color: #fff;
}

#my-calculator {
    z-index: 2389;
}

.toast-header {
    background-color: #000;
    color: #fff;
}

.toast-header {
    cursor: move;
}

.toast-header .close {
    opacity: 1;
}

.toast-body img {
    width: 800px;
}

.more-pannel {
    width: fit-content;
    top: 75px;
    right: 30px;
    background-color: #fff;
    box-shadow: #0006 0 2px 4px;
    border-radius: 8px;
    /*display: flex;
    flex-direction: column;*/
    box-sizing: border-box;
    overflow: hidden;
}

.more-button {
    width: 100%;
    height: 60px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    gap: 10px;
    padding: 10px 20px;
    transition: background-color .1s 0s ease;
}

.more-button:hover {
    background-color: #F0F0F0;
    text-decoration: underline;
    color: #000;
}

.md-button {
    font-weight: bold;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    display: inline-block;
    margin-left: 8px;
    line-height: 1;
    white-space: nowrap;
    background: #fff;
    border: 1px solid #dcdfe6;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    transition: .1s;
    letter-spacing: inherit;
}

/*add*/

#userbutton {
    padding: 6px 12px;
}

a,
button {
    color: inherit;
    -webkit-tap-highlight-color: transparent;
}

.md-button:focus,
.md-button:hover {
    color: #409eff;
    border-color: #c6e2ff;
    background-color: #ecf5ff
}

.md-button:active {
    color: #3a8ee6;
    border-color: #3a8ee6;
    outline: 0
}

.md-button.is-disabled,
.md-button.is-disabled:focus,
.md-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
    background-image: none;
    background-color: #fff;
    border-color: #ebeef5;
    opacity: 1;
}

.toggle-btn {
    margin-right: 10px;
    font-size: 28px;
    color: #404142;
    width: 45px;
    height: 45px;
    line-height: 45px;
    background: transparent;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    outline: 0
}

.toggle-btn:hover {
    color: #404142
}

.toggle-btn:active {
    color: #404142;
    background: #f1f1f1
}

/*
.logo-icon {
	width: 120px
}

.logo-white {
	display: none
}

.logo-dark {
	display: block
}

.logo-icon-2 {
	width: 120px
}

.logo-text {
	color: #404142;
	font-size: 25px
}


.left-topbar .act-logo{width:92px;height:25px;flex-shrink:0;background:url(../images/logo.png) 0 0 no-repeat;}
*/

.ft-button {
    float: left;
    border: none;
    background: none;
    color: #007bff;
    padding: .35rem .3rem;
    margin-top: 24px;
    font-size: .8rem;
    line-height: 1.5;
    border-radius: .2rem
}

.ft-button.is-disabled,
.ft-button.is-disabled:focus,
.ft-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
    opacity: 1;
}

.ft-button:focus,
.ft-button:hover,
.ft-button:active {
    color: #007bff;
    box-shadow: none !important;
}

.ft-button i {
    font-size: 10px;
    font-style: normal
}

.pagination {
    width: calc(100% - 172px);
    overflow: hidden;
    float: left;
    margin: auto 5px;
    position: relative;
    margin-top: 3px;
    /*    margin-left: 10px;
    padding-top: 26px;
    top: -13px;*/
}

.pagination .page-item {
    display: inline-block;
    padding-top: 20px;
}

/*.bi-flag{
	position: relative;
	top:-19px;
	z-index: 300;
}
button.bi-flag{
	top:0px;
}*/

.page-item .page-link {
    width: 32px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    font-size: .8rem;
    color: #606266;
    font-weight: bold
}

.page-item.answered .page-link {
    background-color: #e9ecef;
}

.page-item.active .page-link {
    background-color: #007bff;
}

.page-item.disabled:hover {
    cursor: not-allowed;
}

.page-item.nobr .page-link {
    border: none;
    background: none
}

.highlight,
.eliminator,
.masking {
    color: #007bff;
}

/*
.md-button-group {
	display: inline-block;
	vertical-align: middle;
}
.md-button-group>.md-button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.md-button-group>.md-button:not(:first-child):not(:last-child) {
    border-radius: 0;
}
.md-button-group>.md-button:not(:last-child) {
    margin-right: -4px;
}
.md-button-group>.md-button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.md-button-group>.md-button+.md-button {
    margin-left: 0;
}
.search-bar {
	padding: 0rem 2.4rem
}

.search-arrow-back {
	display: none
}

.search-btn-mobile {
	display: none
}

.search-bar .btn-search-back {
	background: #f1f1f1;
	border: 1px solid #d9d3d3;
	padding: 0 20px;
	border-radius: 2px;
	height: calc(1.3em+.75rem+2px)
}

.search-bar .btn-search-back i {
	font-size: 20px;
	color: #727171
}

.search-bar .btn-search {
	background: #f1f1f1;
	border: 1px solid #d9d3d3;
	padding: 0 30px;
	border-radius: 2px;
	height: calc(1.3em+.75rem+2px)
}

.search-bar .btn-search i {
	font-size: 16px;
	color: #727171
}

.search-bar input {
	height: calc(1.3em+.75rem+2px);
	border: 0;
	border: 1px solid #d9d3d3;
	box-shadow: inset 0 1px 2px #f1f1f1;
	width: 100%;
	border-radius: 2px
}

.dropdown-toggle-nocaret:after {
	display: none
}

.user-box {
	line-height: 1.3;
	height: 60px
}

.user-info {
	text-align: right;
	margin-right: 15px
}

.user-info .user-name {
	font-size: 16px;
	font-weight: 500
}

.user-info .designattion {
	font-size: 14px;
	color: #a9a8a8
}

.user-img {
	width: 48px;
	height: 48px;
	border-radius: 10%;
	border: 1px solid #e5e5e5;
	padding: 3px
}

.user-img img {
	border-radius: 10%
}

.msg-count {
	position: absolute;
	left: 36px;
	top: 19px;
	font-size: 11px;
	font-weight: 500;
	width: 17px;
	height: 17px;
	text-align: center;
	border-radius: 50%;
	z-index: 5;
	padding: 0;
	color: #fff;
	background-color: #007bff;
	display: flex;
	justify-content: center;
	align-items: center
}

.lang i {
	font-size: 18px!important
}

.lang span {
	font-size: 18px!important;
	padding-left: 6px
}
*/

.dropdown-menu {
    -webkit-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    line-height: initial;
    border: 0 solid #e9ecef;
    margin: 0;
    font-size: 16px
}

.top-header .navbar .dropdown-menu {
    -webkit-animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown;
    animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown
}

.top-header .navbar .dropdown-lg .dropdown-menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #007bff;
    position: absolute;
    top: -6px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #007bff;
    border-left: 1px solid #007bff
}

.top-header .navbar .dropdown-menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #ffff;
    position: absolute;
    top: -6px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd
}

@-webkit-keyframes animdropdown {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@keyframes animdropdown {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.dropdown-lg {
    position: relative
}

.dropdown-lg .dropdown-menu {
    width: 380px;
    padding: 0;
    margin: 0;
    border: 0
}

.dropdown-lg .dropdown-menu .dropdown-item {
    padding: .72rem 1.2rem;
    border-bottom: 1px solid #ededed
}

.dropdown-lg .user-online {
    position: relative
}

.dropdown-lg .user-online:after {
    content: '';
    position: absolute;
    bottom: 1px;
    right: 17px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    background: #16e15e
}

.dropdown-lg .msg-avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px
}

.dropdown-lg .msg-name {
    font-size: 14px;
    margin-bottom: 0
}

.dropdown-lg .msg-info {
    font-size: 13px;
    margin-bottom: 0
}

.dropdown-lg .msg-time {
    font-size: 12px;
    margin-bottom: 0;
    color: #919191
}

.dropdown-lg .msg-header {
    padding: .72rem 1.2rem;
    color: #fff;
    border-bottom: 0 solid #ededed;
    background: #007bff;
    text-align: center;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.dropdown-lg .msg-header .msg-header-title {
    font-size: 22px;
    margin-bottom: 0
}

.dropdown-lg .msg-header .msg-header-subtitle {
    font-size: 14px;
    margin-bottom: 0
}

.dropdown-lg .msg-footer {
    padding: .72rem 1.2rem;
    color: #007bff;
    border-top: 0 solid #ededed;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.dropdown-lg .notify {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 22px;
    text-align: center;
    border-radius: 50%;
    background-color: #f1f1f1;
    margin-right: 15px
}

.dropdown-user-profile .dropdown-menu i {
    vertical-align: middle;
    margin-right: 10px
}

.dropdown-user-profile .dropdown-menu span {
    vertical-align: middle
}

.dropdown-language .dropdown-menu i {
    vertical-align: middle;
    margin-right: 10px;
    font-size: 14px
}

.dropdown-language .dropdown-menu span {
    vertical-align: middle;
    font-size: 14px
}

.header-notifications-list {
    position: relative;
    height: 420px
}

.header-message-list {
    position: relative;
    height: 420px
}

.page-wrapper {
    position: relative;
    margin-top: 10px;
    /*	margin-bottom: 200px;
	border: 3px solid #d00;
	height: 600px;
	margin-bottom:10px;*/
}

.sidebar-wrapper {
    width: 50%;
    /*260px*/
    height: calc(100% - 195px);
    padding: 10px;
    padding-top: 5px;
    position: fixed;
    left: 0px;
    /*	float: left;
	top: 60px;
	left: 0;
	bottom: 110px;*/
    z-index: 2200;
    border-right: 5px solid #CCC;
    /*border:3px solid #ff0000;*/
    /*box-shadow: 0 .3rem .6rem rgba(0,0,0,.13);*/
    transition: all .3s ease-out
}

.page-content-wrapper {
    /*margin-left: 54%;/*260px*/
    width: 50%;
    height: calc(100% - 195px);
    padding: 10px;
    padding-top: 5px;
    position: fixed;
    right: 0px;
    transition: all .3s ease-out
}

.wrapper.toggled .page-content-wrapper {
    margin-left: 80px
}

.wrapper.toggled .footer {
    margin-left: 80px
}

.page-content {
    padding: 20px;
    padding-bottom: 50px;
}

sup {
    position: relative;
    font-size: 75%;
    line-height: 0
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /*border-top: 1px solid #cccbcb;
	background-color:#fbfbfb;*/
    background: url('./images/long.svg') no-repeat;
    background-position: 0 1px;
    padding: 10px;
    font-size: 13px;
    text-align: center;
    /*	box-shadow:#0000004d 0 -3px 3px;*/
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2300;
    height: 80px;
    background-color: #fff;
}

.footer2 {
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 0;
    padding: 10px;
    background-color: #fbfbfb;
    box-shadow: #0000004d 0 -3px 3px;
    box-sizing: border-box;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 10
}

.minimap {
    width: fit-content;
    min-width: 400px;
    max-width: 596px;
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: #00000080 0 4px 30px;
    box-sizing: border-box;
    position: fixed;
    transform-origin: bottom;
    left: 50%;
    transform: translate(-50%, calc(-50% - 32px));
    cursor: initial;
    font-family: Roboto;
}

.contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px
}

.section-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    font-weight: 700
}

.section-info-title {
    width: 85%
}

#close-qlist {
    font-size: 1rem;
    position: absolute;
    right: 32px;
    cursor: pointer;
}

.section-pageview {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    font-weight: 700
}

.icon-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border: none;
    border-top: solid #b2b2b2 1px;
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    margin-top: 20px
}

.single-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px
}

.single-detail span {
    font-size: 1rem
}

.detail-img {
    height: 30px;
    width: auto
}

.dashed-square {
    width: 21px;
    height: 21px;
    border: dashed 1px #000;
    margin: 0 5px;
}

.question-list {
    display: flex;
    flex-direction: row;
    gap: 18px;
    border: none;
    border-top: solid #b2b2b2 1px;
    width: 100%;
    padding: 28px 10px 0;
    flex-wrap: wrap;
    box-sizing: border-box
}

.current-position {
    position: absolute;
    width: 30px;
    height: auto;
    bottom: 30px
}

.marked {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 25px;
    left: 25px
    
}

.marked-img {
    width: 20px;
    height: auto
}

.single-question {
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    gap: 2px;
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 10px
}

/*.question-num {
	flex-shrink:0;
	font-size:20px;
	width:100%;
	height:35px;
	border:solid #324dc7 1px;
	border-radius:1px;
	stroke-dasharray:5px;
	display:flex;
	align-items:center;
	justify-content:center;
	background-color:#324dc7;
	color:#fff;
	cursor:pointer;
	font-weight:700;
	transition:all .2s
}
.single-question.no-answer .question-num {
	border-style:dashed;
	color:#324dc7;
	background-color:#fff;
	border-color:#000
}
.single-question.no-answer .question-num:hover {
	background-color:#f0f0f0
}*/

.question-num {
    flex-shrink: 0;
    font-size: 20px;
    width: 100%;
    height: 35px;
    stroke-dasharray: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    transition: all .2s;
    border: 1px dashed #000;
    color: #324dc7;
    background-color: #fff;
}

.question-num.answered {
    border: solid #324dc7 1px;
    color: #fff;
    background-color: #324dc7;
}

.question-num.answered:hover {
    background-color: #324dc7;
    ;
}

.question-num.answered a:hover {
    color: #fff;
}

.question-num:hover {
    background-color: #f0f0f0
}

#current .question-num {
    text-decoration: underline;
}

.name-zone {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 40px;
    gap: 20px
}

.usermap {
    width: fit-content;
    display: none;
    background-color: #fff;
    padding: 0px;
    border-radius: 6px;
    box-shadow: #00000080 0 4px 30px;
    box-sizing: border-box;
    position: fixed;
    transform-origin: bottom;
    left: 20px;
    bottom: 60px;
    cursor: initial;
    font-family: Roboto;
    font-size: 16px;
}

.usermap i {
    font-size: 18px;
    margin-right: 8px
}

.btn-person {
    border: 1px solid #000;
    height: 40px;
    padding: 2px 12px;
    border-radius: 8px;
    background-color: #fff;
    font-size: 1.2rem;
    font-family: roboto;
    font-weight: 700;
}

.dropdown {
    display: inline-block;
}

.dropdown-item {
    padding: 1rem 1.2rem;
}

.dropdown-item:focus,
.dropdown-item:hover {
    border-color: #c6e2ff;
    background-color: #F0F0F0;
    text-decoration: underline;
}

.logo {
    height: 30px;
    width: auto
}

.student-name {
    font-weight: 700
}

.question-info {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    padding: 4px 14px;
    border-radius: 8px;
    border: solid 2px #000;
    font-weight: 700;
    user-select: none;
    cursor: initial;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
    transition: all .2s 0s ease;
    font-family: Roboto;
    font-size: 1.1rem;
    background-color: #000;
    color: #fff;
}

.question-info.disable {
    opacity: .4;
    pointer-events: none;
}

.arrow-up {
    /*	width:0;
	height:0;
	border:solid;
	border-width:0 5px 8px 5px;
	border-color:transparent transparent #fff transparent*/
    width: 10px;
    height: 10px;
    border: solid 2px;
    border-color: transparent transparent #fff #fff;
    transform: rotate(135deg) translate(1px, -1px);
    transition: transform .2s 0s ease;
}

.arrow-up.down {
    /*transform:rotate(180deg)*/
    transform: rotate(-45deg) translate(3px, -3px);
}

.ctrl-pageview {
    background-color: #fff;
    border: 1px solid #324dc7;
    color: #324dc7;
    width: 200px;
}

.ctrl-pageview:hover {
    border: 2px solid #324dc7;
    padding: 0 21px
}

/*
.lis-bg-light {
	background-color: #00005
}

.lis-light {
	color: #707070
}

.price-table {
	-webkit-transition: .3s ease
}

.lis-brd-light {
	border-color: #dadada!important
}

.lis-rounded-top {
	border-top-right-radius: 20px;
	border-top-left-radius: 20px
}

.lis-bg-light {
	background-color: #f7f7f7
}

.lis-latter-spacing-2 {
	letter-spacing: 2px
}

.lis-font-weight-500 {
	font-weight: 500
}

.price-table sup {
	top: -1.5em
}

.price-table sup,.price-table small {
	font-size: 1.25rem
}

.price-table small {
	font-size: 1.25rem
}

sup {
	position: relative;
	font-size: 75%;
	line-height: 0
}

.lis-brd-light {
	border-color: #dadada!important
}

.lis-line-height-3 {
	line-height: 3!important
}

.list-unstyled {
	padding-left: 0;
	list-style: none
}

.lis-line-height-3 {
	line-height: 3!important
}

.lis-rounded-circle-50 {
	border-radius: 50px
}

.lis-bg-primary {
	background-color: #ff214f
}

.price-table.active {
	transform: scale(1.045);
	-webkit-transform: scale(1.045)
}

.price-table {
	-webkit-transition: .3s ease
}

.lis-rounded-bottom {
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px
}
*/

.sidebar-wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;
    background: transparent
}

.sidebar-wrapper .metismenu {
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.sidebar-wrapper .metismenu li+li {
    margin-top: 5px
}

.sidebar-wrapper .metismenu li:first-child {
    margin-top: 5px
}

.sidebar-wrapper .metismenu li:last-child {
    margin-bottom: 5px
}

.sidebar-wrapper .metismenu>li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative
}

.sidebar-wrapper .metismenu a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px 15px;
    font-size: 15px;
    color: #5f5f5f;
    outline-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: .5px;
    border: 1px solid #ededed;
    transition: all .3s ease-out
}

.sidebar-wrapper .metismenu a .parent-icon {
    font-size: 24px;
    line-height: 1
}

.sidebar-wrapper .metismenu a .menu-title {
    margin-left: 10px
}

.sidebar-wrapper .metismenu ul a {
    padding: 8px 15px 8px 15px;
    font-size: 15px;
    border: 0
}

.sidebar-wrapper .metismenu ul a i {
    margin-right: 10px
}

.sidebar-wrapper .metismenu ul {
    border: 1px solid #ededed
}

.sidebar-wrapper .metismenu ul ul a {
    padding: 8px 15px 8px 30px
}

.sidebar-wrapper .metismenu ul ul ul a {
    padding: 8px 15px 8px 45px
}

.sidebar-wrapper .metismenu a:hover,
.sidebar-wrapper .metismenu a:focus,
.sidebar-wrapper .metismenu a:active,
.sidebar-wrapper .metismenu .mm-active>a {
    color: #363636;
    text-decoration: none;
    background: #ededed
}

.menu-label {
    padding: 20px 15px 5px 5px;
    color: #b0afaf;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: .5px
}

.metismenu .has-arrow:after {
    position: absolute;
    content: "";
    width: .50em;
    height: .50em;
    border-style: solid;
    border-width: 1.2px 0 0 1.2px;
    border-color: initial;
    right: 15px;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top;
    top: 50%;
    transition: all .3s ease-out
}

.p-tab-name {
    display: none !important
}

@media screen and (min-width:576px) {
    .p-tab-name {
        display: block !important
    }
}

@media screen and (min-width:1025px) {
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper {
        width: 80px
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu a {
        justify-content: center
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu .menu-title {
        display: none
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li ul {
        display: none
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li.menu-label {
        display: none
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu .has-arrow:after {
        display: none
    }
    .sidebar-header {
        display: none !important
    }
    .chat-toggle-btn {
        display: none !important
    }
    .email-toggle-btn {
        display: none !important
    }
}

input::placeholder {
    color: #000 !important;
    opacity: .3 !important
}

.custom-file-label {
    font-size: 1rem
}

.form-check-label {
    font-size: 1.1rem
}

.form-text {
    font-size: 13px
}

.invalid-feedback {
    font-size: 100%;
    margin-top: .55rem
}

.custom-control-label {
    font-size: 1rem
}

/*
.select2-container--bootstrap4 .select2-selection--single {
	font-size: 1rem
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
	font-size: 1rem
}

.select2-sm .select2-container--bootstrap4 .select2-selection {
	height: calc(1.5em+.5rem+2px);
	font-size: .875rem
}

.select2-container--bootstrap4 .select2-dropdown {
	border-color: rgba(206,212,218,0.55);
	border-radius: 0;
	box-shadow: 0 .1rem .7rem rgba(0,0,0,.10)
}

.g-r-left .select2-container--bootstrap4 .select2-selection {
	border-top-left-radius: 0!important;
	border-bottom-left-radius: 0!important
}

.g-r-right .select2-container--bootstrap4 .select2-selection {
	border-top-right-radius: 0!important;
	border-bottom-right-radius: 0!important
}

.scrollspy-1 {
	height: 200px;
	position: relative;
	overflow-y: scroll
}

.scrollspy-2 {
	height: 200px;
	position: relative;
	overflow-y: scroll
}

.chip {
	display: inline-block;
	height: 32px;
	padding: 0 12px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	font-size: 14px;
	font-weight: 500;
	line-height: 32px;
	color: rgba(0,0,0,0.70);
	cursor: pointer;
	background-color: #f1f1f1;
	border: 1px solid rgba(0,0,0,0.15);
	border-radius: 16px;
	-webkit-transition: all .3s linear;
	transition: all .3s linear;
	box-shadow: none
}

.chip img {
	float: left;
	width: 32px;
	height: 32px;
	margin: 0 8px 0 -12px;
	border-radius: 50%
}

.chip .closebtn {
	padding-left: 10px;
	font-weight: bold;
	float: right;
	font-size: 16px;
	cursor: pointer
}

.chip.chip-md {
	height: 42px;
	line-height: 42px;
	border-radius: 21px
}

.chip.chip-md img {
	height: 42px;
	width: 42px
}

.chip.chip-lg {
	height: 52px;
	line-height: 52px;
	border-radius: 26px
}

.chip.chip-lg img {
	height: 52px;
	width: 52px
}
*/

.card {
    box-shadow: 0 .1rem .7rem rgba(0, 0, 0, .10);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-bottom: 30px
}

.card-header {
    background-color: transparent;
    font-size: 16px;
    text-transform: capitalize
}

.card-group {
    margin-bottom: 30px
}

.card-group {
    box-shadow: 0 .1rem .7rem rgba(0, 0, 0, .10)
}

.card-group .card {
    box-shadow: none
}

.metismenu-card .card-header {
    cursor: pointer
}

.card-deck .card {
    margin-bottom: 30px
}

.btn {
    text-transform: capitalize
}

.btn i {
    vertical-align: middle;
    font-size: 1.3rem;
    margin-top: -1em;
    margin-bottom: -1em
}

.btn-white {
    color: #212529;
    background-color: #fff;
    border-color: #ced4da
}

/*
.btn-facebook {
	box-shadow: 0 2px 2px 0 rgba(59,89,152,0.14),0 3px 1px -2px rgba(59,89,152,0.2),0 1px 5px 0 rgba(59,89,152,0.12);
	background-color: #3b5998;
	border-color: #3b5998;
	color: #fff
}

.btn-facebook:hover {
	color: #fff
}

.btn-facebook:focus {
	box-shadow: 0 0 0 .2rem rgba(59,89,152,0.5)
}

.btn-twitter {
	box-shadow: 0 2px 2px 0 rgba(85,172,238,0.14),0 3px 1px -2px rgba(85,172,238,0.2),0 1px 5px 0 rgba(85,172,238,0.12);
	background-color: #55acee;
	border-color: #55acee;
	color: #fff
}

.btn-twitter:hover {
	color: #fff
}

.btn-twitter:focus {
	box-shadow: 0 0 0 .2rem rgba(85,172,238,0.5)
}

.btn-linkedin {
	box-shadow: 0 2px 2px 0 rgba(9,118,180,0.14),0 3px 1px -2px rgba(9,118,180,0.2),0 1px 5px 0 rgba(9,118,180,0.12);
	background-color: #0976b4;
	border-color: #0976b4;
	color: #fff
}

.btn-linkedin:hover {
	color: #fff
}

.btn-linkedin:focus {
	box-shadow: 0 0 0 .2rem rgba(9,118,180,0.5)
}

.btn-youtube {
	box-shadow: 0 2px 2px 0 rgba(229,45,39,0.14),0 3px 1px -2px rgba(229,45,39,0.2),0 1px 5px 0 rgba(229,45,39,0.12);
	background-color: #e52d27;
	border-color: #e52d27;
	color: #fff
}

.btn-youtube:hover {
	color: #fff
}

.btn-youtube:focus {
	box-shadow: 0 0 0 .2rem rgba(229,45,39,0.5)
}

.btn-github {
	box-shadow: 0 2px 2px 0 rgba(51,51,51,0.14),0 3px 1px -2px rgba(51,51,51,0.2),0 1px 5px 0 rgba(51,51,51,0.12);
	background-color: #333;
	border-color: #333;
	color: #fff
}

.btn-github:hover {
	color: #fff
}

.btn-github:focus {
	box-shadow: 0 0 0 .2rem rgba(51,51,51,0.5)
}

.btn-skype {
	box-shadow: 0 2px 2px 0 rgba(85,172,238,0.14),0 3px 1px -2px rgba(85,172,238,0.2),0 1px 5px 0 rgba(85,172,238,0.12);
	background-color: #00aff0;
	border-color: #00aff0;
	color: #fff
}

.btn-skype:hover {
	color: #fff
}

.btn-skype:focus {
	box-shadow: 0 0 0 .2rem rgba(0,175,240,0.5)
}

.btn-pinterest {
	box-shadow: 0 2px 2px 0 rgba(204,33,39,0.14),0 3px 1px -2px rgba(204,33,39,0.2),0 1px 5px 0 rgba(204,33,39,0.12);
	background-color: #cc2127;
	border-color: #cc2127;
	color: #fff
}

.btn-pinterest:hover {
	color: #fff
}

.btn-pinterest:focus {
	box-shadow: 0 0 0 .2rem rgba(204,33,39,0.5)
}

.btn-dribbble {
	box-shadow: 0 2px 2px 0 rgba(234,76,137,0.14),0 3px 1px -2px rgba(234,76,137,0.2),0 1px 5px 0 rgba(234,76,137,0.12);
	background-color: #ea4c89;
	border-color: #ea4c89;
	color: #fff
}

.btn-dribbble:hover {
	color: #fff
}

.btn-dribbble:focus {
	box-shadow: 0 0 0 .2rem rgba(234,76,137,0.5)
}
*/

/*
.btn-group-round button:first-child {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px
}

.btn-group-round button:last-child {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px
}

.bg-rose {
	background-color: #ff007c!important
}

.bg-voilet {
	background-color: #7d00b5!important
}

.bg-dribbble {
	background-color: #ea4c89!important
}

.bg-facebook {
	background-color: #3b5998!important
}

.bg-twitter {
	background-color: #55acee!important
}

.bg-google {
	background-color: #e52d27!important
}

.bg-linkedin {
	background-color: #0976b4!important
}

.bg-body {
	background-color: #f6f6f6!important
}

.bg-gray {
	background-color: #dee1e6!important
}

.bg-red-light {
	background-color: #fe6555!important
}

.bg-primary-blue {
	background-color: #265ed7!important
}

.bg-dark-blue {
	background-color: #0c1b7a!important
}

.bg-shine-info {
	background-color: #54dee4!important
}

.bg-wall {
	background-color: #00cd98!important
}

.bg-sunset {
	background-color: #ff8b01!important
}

.bg-light-primary {
	background-color: #d9ebff!important
}

.bg-light-success {
	background-color: #d6efdc!important
}

.bg-light-danger {
	background-color: #ffe6e8!important
}

.bg-light-warning {
	background-color: #fff3d1!important
}

.bg-light-info {
	background-color: #ccf3f9!important
}

.bg-light-purple {
	background-color: #dcdcff!important
}

.bg-light-shineblue {
	background-color: #ccf7ff!important
}

.bg-light-cyne {
	background-color: #cce8e8!important
}

.bg-split-primary {
	background-color: #0270e6;
	border-color: #0462c7
}

.bg-light-sinata {
	background-color: #f7cbff!important
}

.bg-light-mehandi {
	background: #f0ffb8!important
}

.bg-gradient-danger {
	background-image: linear-gradient(to left,#ff758c 0,#ff7eb3 100%)
}

.bg-gradient-voilet {
	background-image: linear-gradient(to left,#a18cd1 0,#fbc2eb 100%)
}

.bg-gradient-success {
	background-image: linear-gradient(to left,#05ac9d 0,#20cfbf 100%)
}

.bg-gradient-mehandi {
	background-image: linear-gradient(to right,#dbe287,#cac531)
}

.text-facebook {
	color: #3b5998!important
}

.text-twitter {
	color: #55acee!important
}

.text-skype {
	color: #00aff0!important
}

.text-purple {
	color: #8280ff!important
}

.text-shineblue {
	color: #01d7ff!important
}

.text-cyne {
	color: #008b8b!important
}

.text-primary-blue {
	color: #265ed7!important
}

.text-dark-blue {
	color: #0c1b7a!important
}

.text-shine-info {
	color: #54dee4!important
}

.text-grey {
	color: #dee1e6!important
}

.text-sinata {
	color: #a52bbb!important
}

.text-mehandi {
	color: #839838!important
}

.section-authentication {
	height: 100%;
	padding-top: 1.0rem;
	padding-bottom: 1.0rem
}

.authentication-forgot {
	height: 100vh;
	padding: 0 1rem
}

.forgot-box {
	width: 27rem;
	border-radius: 1.25rem
}

.authentication-reset-password {
	height: 100vh;
	padding: 0 1rem
}

.authentication-lock-screen {
	height: 100vh;
	padding: 0 1rem
}
*/

.error-404 {
    height: 100vh;
    padding: 0 1rem
}

/*
.login-card {
	border-right: 1px solid #dee2e6!important
}

.error-social a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 18px;
	color: #fff;
	text-align: center;
	border-radius: 50%;
	margin: 5px;
	box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important
}

.bg-forgot {
	background-image: url(../images/login-images/4087092.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat
}

.bg-lock-screen {
	background-image: url(../images/login-images/lock-screen-img.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat
}

.fm-wrapper {
	position: relative
}

.fm-left-sidebar {
	background: #fff;
	width: 220px;
	height: 100%;
	position: fixed;
	padding: 10px;
	overflow-x: hidden;
	overflow-y: scroll
}

.fm-body {
	background: white;
	width: 100%;
	height: 100%
}

.fm-menu .list-group a {
	font-size: 16px;
	color: #5f5f5f;
	display: flex;
	align-items: center
}

.fm-menu .list-group a i {
	font-size: 23px
}

.fm-menu .list-group a:hover {
	background: #007bff;
	color: #fff;
	transition: all .2s ease-out
}

.fm-icon-box {
	font-size: 32px;
	background: #fff;
	width: 52px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: .25rem
}

.fm-file-box {
	font-size: 25px;
	background: #e9ecef;
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: .25rem
}

.user-groups img {
	margin-left: -14px;
	border: 1px solid #e4e4e4;
	padding: 2px;
	cursor: pointer
}

.user-plus {
	width: 33px;
	height: 33px;
	margin-left: -14px;
	line-height: 33px;
	background: #fff;
	border-radius: 50%;
	text-align: center;
	font-size: 22px;
	cursor: pointer;
	border: 1px dotted #a9b2bb;
	color: #404142
}
*/

.table-responsive {
    white-space: nowrap
}

.table td,
.table th {
    vertical-align: middle;
    color: #6d757b
}

/*
#invoice {
	padding: 0
}

.invoice {
	position: relative;
	background-color: transparent;
	min-height: 680px;
	padding: 15px
}

.invoice header {
	padding: 10px 0;
	margin-bottom: 20px;
	border-bottom: 1px solid #007bff
}

.invoice .company-details {
	text-align: right
}

.invoice .company-details .name {
	margin-top: 0;
	margin-bottom: 0
}

.invoice .contacts {
	margin-bottom: 20px
}

.invoice .invoice-to {
	text-align: left
}

.invoice .invoice-to .to {
	margin-top: 0;
	margin-bottom: 0
}

.invoice .invoice-details {
	text-align: right
}

.invoice .invoice-details .invoice-id {
	margin-top: 0;
	color: #007bff
}

.invoice main {
	padding-bottom: 50px
}

.invoice main .thanks {
	margin-top: -100px;
	font-size: 2em;
	margin-bottom: 50px
}

.invoice main .notices {
	padding-left: 6px;
	border-left: 6px solid #007bff;
	background: #e7f2ff;
	padding: 10px
}

.invoice main .notices .notice {
	font-size: 1.2em
}

.invoice table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	margin-bottom: 20px
}

.invoice table td,.invoice table th {
	padding: 15px;
	background: #eee;
	border-bottom: 1px solid #fff
}

.invoice table th {
	white-space: nowrap;
	font-weight: 400;
	font-size: 16px
}

.invoice table td h3 {
	margin: 0;
	font-weight: 400;
	color: #007bff;
	font-size: 1.2em
}

.invoice table .qty,.invoice table .total,.invoice table .unit {
	text-align: right;
	font-size: 1.2em
}

.invoice table .no {
	color: #fff;
	font-size: 1.6em;
	background: #007bff
}

.invoice table .unit {
	background: #ddd
}

.invoice table .total {
	background: #007bff;
	color: #fff
}

.invoice table tbody tr:last-child td {
	border: 0
}

.invoice table tfoot td {
	background: 0;
	border-bottom: 0;
	white-space: nowrap;
	text-align: right;
	padding: 10px 20px;
	font-size: 1.2em;
	border-top: 1px solid #aaa
}

.invoice table tfoot tr:first-child td {
	border-top: 0
}

.invoice table tfoot tr:last-child td {
	color: #007bff;
	font-size: 1.4em;
	border-top: 1px solid #007bff
}

.invoice table tfoot tr td:first-child {
	border: 0
}

.invoice footer {
	width: 100%;
	text-align: center;
	color: #777;
	border-top: 1px solid #aaa;
	padding: 8px 0
}

@media print {
	.invoice {
		font-size: 11px!important;
		overflow: hidden!important
	}

	.invoice footer {
		position: absolute;
		bottom: 10px;
		page-break-after: always
	}

	.invoice>div:last-child {
		page-break-before: always
	}
}

.main-row {
	height: 100vh
}

.main-col {
	max-width: 500px;
	min-height: 300px
}

.todo-done {
	text-decoration: line-through
}

.chat-wrapper {
	width: auto;
	height: 600px;
	border-radius: .25rem;
	position: relative;
	background: #fff;
	box-shadow: 0 .1rem .7rem rgba(0,0,0,.10)
}

.chat-sidebar {
	width: 340px;
	height: 100%;
	position: absolute;
	background: #f8f9fa;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
	overflow: hidden;
	border-right: 1px solid rgba(0,0,0,.125);
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.chat-sidebar-header {
	width: auto;
	height: auto;
	position: relative;
	background: #fff;
	border-bottom: 1px solid rgba(0,0,0,.125);
	border-right: 0 solid rgba(0,0,0,.125);
	border-top-left-radius: .25rem;
	padding: 15px
}

.chat-sidebar-content {
	padding: 0
}

.chat-user-online {
	position: relative
}

.chat-sidebar-header .chat-user-online:before {
	content: '';
	position: absolute;
	bottom: 7px;
	left: 40px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	box-shadow: 0 0 0 2px #fff;
	background: #16e15e
}

.chat-list .chat-user-online:before {
	content: '';
	position: absolute;
	bottom: 7px;
	left: 36px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	box-shadow: 0 0 0 2px #fff;
	background: #16e15e
}

.chat-content {
	margin-left: 340px;
	padding: 85px 15px 15px 15px
}

.chat-header {
	position: absolute;
	height: 60px;
	left: 340px;
	right: 0;
	top: 0;
	padding: 15px;
	background: #f8f9fa;
	border-bottom: 1px solid rgba(0,0,0,.125);
	border-top-right-radius: .25rem;
	z-index: 1
}

.chat-footer {
	position: absolute;
	height: 60px;
	left: 340px;
	right: 0;
	bottom: 0;
	padding: 15px;
	background: #f8f9fa;
	border-top: 1px solid rgba(0,0,0,.125);
	border-bottom-right-radius: .25rem
}

.chat-footer-menu a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 18px;
	color: #6c757d;
	text-align: center;
	border-radius: 50%;
	margin: 3px;
	background-color: white;
	border: 1px solid rgb(0 0 0 / 15%)
}

.chat-tab-menu li a.nav-link {
	padding: .3rem .2rem;
	line-height: 1.2;
	color: #4a4b4c
}

.chat-tab-menu .nav-pills .nav-link.active,.chat-tab-menu .nav-pills .show>.nav-link {
	color: #007bff;
	background-color: rgb(0 123 255 / 0)
}

.chat-title {
	font-size: 16px;
	color: #272b2f
}

.chat-msg {
	font-size: 14px;
	color: #6c757d
}

.chat-time {
	font-size: 13px;
	color: #6c757d
}

.chat-list {
	position: relative;
	height: 300px
}

.chat-list .list-group-item {
	border: 1px solid rgb(0 0 0 / 0);
	background-color: transparent
}

.chat-list .list-group-item:hover {
	border: 1px solid rgb(0 0 0 / 0);
	background-color: rgb(0 123 255 / 15%)
}

.chat-list .list-group-item.active {
	background-color: rgb(0 123 255 / 15%)
}

.chart-online {
	color: #16e15e
}

.chat-top-header-menu a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 18px;
	color: #6c757d;
	text-align: center;
	border-radius: 50%;
	margin: 3px;
	background-color: white;
	border: 1px solid rgb(0 0 0 / 15%)
}

.chat-content {
	position: relative;
	width: auto;
	height: 520px
}

.chat-content-leftside .chat-left-msg {
	width: fit-content;
	background-color: #eff2f5;
	padding: .80rem;
	border-radius: 12px;
	max-width: 480px;
	text-align: left;
	border-top-left-radius: 0
}

.chat-content-rightside .chat-right-msg {
	width: fit-content;
	background-color: #dcedff;
	padding: .80rem;
	border-radius: 12px;
	float: right;
	max-width: 480px;
	text-align: left;
	border-bottom-right-radius: 0
}

.chat-toggle-btn {
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin-right: 15px;
	text-align: center;
	font-size: 24px;
	color: #6c757d;
	border-radius: 50%;
	cursor: pointer;
	background-color: white;
	border: 1px solid rgb(0 0 0 / 15%)
}

.email-wrapper {
	width: auto;
	height: 600px;
	overflow: hidden;
	border-radius: .25rem;
	position: relative;
	background: #fff;
	box-shadow: 0 .1rem .7rem rgba(0,0,0,.10)
}

.email-sidebar {
	width: 250px;
	height: 100%;
	position: absolute;
	background: #fff;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
	overflow: hidden;
	border-right: 1px solid rgba(0,0,0,.125);
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.email-sidebar-header {
	width: auto;
	height: auto;
	position: relative;
	background: #fff;
	border-bottom: 1px solid rgba(0,0,0,.125);
	border-right: 0 solid rgba(0,0,0,.125);
	border-top-left-radius: .25rem;
	padding: 15px
}

.email-navigation {
	position: relative;
	padding: 0;
	height: 330px;
	border-bottom: 1px solid rgba(0,0,0,.125)
}

.email-header {
	position: absolute;
	height: 60px;
	left: 250px;
	right: 0;
	top: 0;
	padding: 15px;
	background: #fff;
	border-bottom: 1px solid rgba(0,0,0,.125);
	border-top-right-radius: .25rem;
	z-index: 1
}

.email-content {
	position: absolute;
	left: 0;
	right: 0;
	width: auto;
	top: 60px;
	height: auto;
	margin-left: 250px;
	padding: 0;
	background: #fff;
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.email-navigation a.list-group-item {
	color: #404142;
	padding: .15rem 1.25rem;
	background-color: white;
	border-bottom: 1px solid rgb(0 0 0 / 0);
	transition: all .3s ease-out
}

.email-navigation a.list-group-item:hover {
	background-color: rgb(0 123 255 / 15%)
}

.email-navigation a.list-group-item.active {
	color: #007bff;
	font-weight: 600;
	background-color: rgb(0 123 255 / 15%)
}

.email-meeting {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0
}

.email-meeting a.list-group-item {
	color: #404142;
	padding: .15rem 1.25rem;
	background-color: white;
	border-bottom: 1px solid rgb(0 0 0 / 0)
}

.email-meeting a.list-group-item:hover {
	background-color: rgb(0 123 255 / 15%);
	transition: all .3s ease-out
}

.email-hangout .chat-user-online:before {
	content: '';
	position: absolute;
	bottom: 7px;
	left: 37px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	box-shadow: 0 0 0 2px #fff;
	background: #16e15e
}

.email-toggle-btn {
	width: auto;
	height: auto;
	margin-right: 10px;
	text-align: center;
	font-size: 24px;
	color: #404142;
	border-radius: 0;
	cursor: pointer;
	background-color: transparent;
	border: 0 solid rgb(0 0 0 / 15%)
}

.email-actions {
	width: 230px
}

.email-time {
	font-size: 13px;
	color: #6c757d
}

.email-list div.email-message {
	background: #fff;
	border-bottom: 1px solid rgb(0 0 0 / 8%);
	color: #383a3c
}

.email-list div.email-message:hover {
	transition: all .2s ease-out;
	background-color: #eceef1
}

.email-list {
	position: relative;
	height: 530px
}

.email-star {
	color: #6c757d
}

.email-read-box {
	position: relative;
	height: 530px
}

.compose-mail-popup {
	width: 42%;
	position: fixed;
	bottom: -30px;
	right: 30px;
	z-index: 15;
	display: none
}

.compose-mail-toggled {
	display: block
}

.compose-mail-title {
	font-size: 16px
}

.compose-mail-close {
	width: 25px;
	height: 25px;
	line-height: 25px;
	text-align: center;
	font-size: 14px;
	border-radius: 2px;
	background-color: rgb(255 255 255 / 0)
}

.compose-mail-close:hover {
	background-color: rgb(255 255 255 / 20%)
}

.contacts-social a {
	font-size: 16px;
	width: 26px;
	height: 26px;
	line-height: 26px;
	background: #f7f5f5;
	text-align: center;
	border-radius: .25rem
}

.iconFilterTypes {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px
}

.iconFilterTypes .tab.active,.iconFilterTypes .tab:hover {
	font-weight: 700;
	color: #756af8
}

.iconFilterTypes .tab {
	color: #000;
	font-weight: 700;
	display: inline-block;
	cursor: pointer
}

.list {
	position: relative
}

ul.icons {
	list-style: none
}

ul.icons li {
	position: relative
}

ul.icons li {
	cursor: pointer;
	padding: 10px;
	width: 204px;
	float: left;
	box-shadow: 0 1px 5px #e3e7eb;
	margin: 13px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	background: #f8f9fb;
	text-overflow: ellipsis;
	white-space: nowrap;
	-webkit-transition: all .1s linear;
	-moz-transition: all .1s linear;
	-o-transition: all .1s linear;
	transition: all .1s linear
}

ul.icons li i {
	color: #007bff;
	width: 30px;
	height: 30px;
	font-size: 30px;
	text-align: center;
	display: block;
	float: left;
	line-height: 34px;
	margin-left: 5px
}

ul.icons li:hover {
	background: #eef1f7
}

ul.icons li span {
	text-indent: 15px;
	display: block;
	line-height: 32px;
	overflow: hidden;
	text-overflow: ellipsis
}

.pByfF {
	animation-fill-mode: forwards;
	cursor: pointer;
	display: inline-block;
	flex: 0 0 10%;
	transition: all .15s ease 0s;
	border-radius: 8px;
	overflow: hidden;
	outline: none!important;
	background: #f8f9fb;
	box-shadow: 0 1px 5px #e3e7eb;
	width: 122px;
	height: 122px;
	margin: 7px 7px;
	padding: 10px 14px
}

.pByfF.active .icon-box-inner {
	background: #eee
}

.pByfF .icon-box-inner {
	font-weight: 700;
	position: relative;
	font-size: 16px;
	line-height: 35px;
	text-align: center;
	pointer-events: none;
	margin: 1px;
	border-radius: 8px;
	background: transparent;
	padding: 18px 10px
}

.pByfF i {
	color: #007bff;
	font-size: 36px
}

.pByfF .icon-box-inner .icon-box-name {
	font-size: 12px;
	text-transform: capitalize;
	user-select: none;
	color: #a5a5a5;
	font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 15px;
	overflow: hidden;
	margin: 5px auto 2px
}

.pByfF:hover {
	background: #eaecf1
}

.media-icons {
	font-size: 54px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	color: #fff;
	border-radius: 50%
}

.dashboard-icons {
	width: 55px;
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ededed;
	font-size: 42px;
	border-radius: 10px
}

.widgets-icons {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ededed;
	font-size: 26px;
	border-radius: 10px
}

.icon-box {
	padding: 10px 14px;
	background: #fff;
	box-shadow: 0 1px 5px #e3e7eb
}

.icon-symbol i {
	font-size: 30px;
	color: #007bff
}
*/

/*
.p-10 {
	padding: 10px
}

.p-15 {
	padding: 15px
}

.font-small {
	font-size: 50%
}

.font-13 {
	font-size: 13px
}

.font-14 {
	font-size: 14px
}

.font-20 {
	font-size: 20px
}

.font-24 {
	font-size: 24px
}

.font-30 {
	font-size: 30px
}

.font-60 {
	font-size: 60px
}

.radius-10 {
	border-radius: 10px
}

.radius-30 {
	border-radius: 30px
}

.cursor-pointer {
	cursor: pointer
}

.chart-container1 {
	position: relative;
	height: 340px
}

.chart-container2 {
	position: relative;
	height: 400px
}

.chart-container3 {
	position: relative;
	width: 200px;
	height: 80px;
	right: -1.25rem;
	top: -1.0rem
}

.gmaps,.gmaps-panaroma {
	height: 400px;
	background: #eee;
	border-radius: 3px
}

.product-img {
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f3f3f3;
	border-radius: 10px
}

.page-breadcrumb .breadcrumb {
	background-color: transparent
}

.breadcrumb-title {
	font-size: 20px;
	border-right: 1.5px solid #aaa4a4
}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
	font-size: 16px
}

.page-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
	display: inline-block;
	padding-right: .5rem;
	color: #6c757d;
	font-family: 'LineIcons';
	content: "\ea5c"
}*/

.vertical-align-middle {
    vertical-align: middle
}

.back-to-top {
    display: none;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 26px;
    color: white;
    position: fixed;
    border-radius: 10px;
    bottom: 20px;
    right: 12px;
    background-color: #007bff;
    z-index: 10000
}

.back-to-top:hover {
    color: white;
    background-color: #000;
    transition: all .5s
}

/*
.switcher-wrapper {
	width: 240px;
	height: 100%;
	position: fixed;
	right: -240px;
	top: 0;
	bottom: 0;
	z-index: 16;
	background: #fff;
	border-left: 0 solid #d2d2d2;
	box-shadow: 0 .3rem .6rem rgba(0,0,0,.13);
	transition: all .2s ease-out
}

.switcher-btn {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 24px;
	background: #007bff;
	box-shadow: 0 .3rem .6rem rgba(0,0,0,.13);
	color: #fff;
	text-align: center;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	position: absolute;
	top: 40%;
	right: 100%;
	cursor: pointer
}

.switcher-wrapper.switcher-toggled {
	right: 0
}

.switcher-body {
	padding: 1.25rem
}*/

.sidebar-content {
    font-family: 'Noto Serif', serif;
    font-size: 1.1rem;
}

.page-content {
    font-family: 'Noto Serif', serif;
    font-size: 1.1rem;
}

.sidebar-content {
    padding: 10px 20px
}

span.underline {
    text-decoration: underline
}

span.overline {
    text-decoration: overline
}

span.bold {
    font-weight: bold
}

span.italic {
    font-style: italic
}

.question-title {
    width: 100%;
    height: 40px;
    background-color: #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.question-index {
    width: 40px;
    height: 40px;
    background-color: #141414;
    font-weight: 900;
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.bookmark {
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;
}

.bookmark.marked {
    font-weight: 700;
}

.cross-line {
    position: absolute;
    width: calc(100% + 10px);
    border: solid #000 1px;
    left: -5px;
}

.crossing-zone {
    width: 40px;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
    user-select: none;
    display: none;
}

.cross-label {
    position: absolute;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid #000 1px;
    border-radius: 50%;
    font-size: 13px;
    font-weight: 600;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cross-btn-line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    border: solid #000 1px;
    margin: 0;
}

.undo-cross {
    text-decoration: underline;
    font-weight: 700;
    font-family: Roboto;
}

.crossing-options {
    margin-left: auto;
    height: 36px;
    box-sizing: border-box;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    user-select: none;
}

.bookmark-img,
.crossing-options-img {
    width: auto;
    height: 100%;
    /*bookmark_filled.png crossAbcActive.png*/
}

.q-dash {
    height: 6px;
    background: url(./images/short.svg) no-repeat;
    background-position: 0 2px;
}

.question-content {
    margin-top: 15px;
}

.question-content p {
    margin-bottom: 12px;
}

.answer-zone {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.answer-preview {
    margin-top: 40px;
    font-family: Minion Pro, serif;
    font-weight: 900;
    font-size: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.answer-preview .value,
.answer-preview .value span {
    display: flex;
    flex-direction: column;
    font-family: Minion Pro, serif;
    font-weight: 900;
    font-size: 28px;
    align-items: center;
    margin: 0;
}

.answer-preview .value span {
    font-size: 20px;
}

.answer-preview .value hr {
    width: 100%;
    margin: 0;
    border-top: solid 2px #000;
}

.option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.select-zone {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 14px;
    width: 100%;
    box-sizing: border-box;
    border: solid 1px #000;
    border-radius: 7px;
    cursor: pointer;
    position: relative;
}

.select-zone.crossed .option-label,
.select-zone.crossed .option-content {
    opacity: .4;
}

.option input[type="radio"] {
    display: none;
}

.option label {
    margin-bottom: 0;
}

.option input[type="radio"]:checked+label {
    border: solid 3px #324dc7;
}

.option input[type="radio"]:checked+label div.option-label {
    background-color: #324dc7;
    color: #fff;
}

/*.select-zone:hover,.option.selected .select-zone
{
  background-color: #0077c8;
  border-color: #0077c8;
  color: #fff;
}*/

.option-label {
    width: 26px;
    height: 26px;
    border: solid 2px #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    box-sizing: border-box;
    font-weight: 900;
    font-size: 13px;
    flex-shrink: 0;
}

.option-content {
    font-family: "Noto Serif", serif;
}

.input-wrapper {
    border: solid #000 1px;
    border-radius: 10px;
    padding: 12px;
    width: 130px;
    box-sizing: border-box;
}

.answer-input {
    font-size: 25px;
    padding: 2px;
    border: none;
    border-bottom: solid #000 1px;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 3px;
    text-align: center;
}

.answer-input:focus {
    outline: none;
    border-bottom-width: 2px;
}

.question-title .bookmark {
    background-image: url("./images/bookmark_empty.png");
    background-size: contain;
    /* 背景图像等比例缩放，尽量填充容器 */
    background-repeat: no-repeat;
    /* 防止背景图像重复 */
    padding-left: 36px;
    font-family: Roboto;
}

.question-title .bookmark.filled {
    background-image: url("./images/bookmark_filled.png");
    font-weight: 600;
}

.question-title .crossing-options {
    background-image: url("./images/crossAbc.png");
    background-size: contain;
    /* 背景图像等比例缩放，尽量填充容器 */
    background-repeat: no-repeat;
    /* 防止背景图像重复 */
    padding-left: 36px;
}

.question-title .crossing-options.filled {
    background-image: url("./images/crossAbcActive.png");
}

.single-detail .bi-bookmark-fill {
    font-size: 1.3rem;
    color: #c13145;
}

.single-question .bi-bookmark-fill {
    font-size: 0.8rem;
    color: #c13145;
}

.resizer-img {
    width: 25px;
    height: auto;
}

.resizer-img.right {
    transform: scaleX(-1);
}

.resizer {
    position: absolute;
    top: 20px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: #000000b3 0 4px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    opacity: .6;
    z-index: 1000;
    margin-bottom: 30px;
}

.sidebar-wrapper.large {
    width: 66%;
}

.sidebar-wrapper.small {
    width: 34%;
}

.page-content-wrapper.large {
    width: 66%;
}

.page-content-wrapper.small {
    width: 34%;
}

.sidebar-wrapper.large .resizer-img {
    transform: rotate(180deg);
}

.page-content-wrapper.large .resizer-img {
    transform: rotate(90deg);
}

.sidebar-content {
    margin-top: 45px;
}

.page-content {}

.resize {
    position: relative;
    top: 0px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: #000000b3 0 4px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    opacity: .5;
    z-index: 1000;
}

.resize.left {
    float: right;
    right: 6px;
}

.resize.right {
    left: 6px;
}

.resize:hover {
    opacity: .8
}

.ctrl-btns-container a:hover {
    color: #fff
}

.ctrl-btns-container a:active {
    text-decoration: underline
}

.format_answer math {
    display: inline-block;
    font-size: 24px;
    font-weight: 600;
}

.grid_direction {}

.grid_direction h2 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.grid_direction ul {
    list-style: disc;
    margin-left: 30px;
    margin-bottom: 30px;
}

.grid_direction .example {
    text-align: center;
}

.grid_direction table {
    width: 70%;
    margin: 0 auto !important;
}

.sidebar-content ul {
    list-style: disc;
    padding-left: 42px;
}

@media screen and (max-width:1280px) {
    /*	.left-topbar {
		width: auto
	}
*/
    .mid-bar {
        padding: 0rem .5rem
    }
    .search-bar {
        padding: 0rem 1.4rem
    }
    /*	.email-header {
		height: auto
	}

	.email-content {
		padding: 100px 0 0 0
	}*/
}

@media screen and (max-width:1024px) {
    .search-bar .btn-search-back {
        background: #fff;
        border: 1px solid #fff;
        padding: 0 10px
    }
    .search-arrow-back {
        display: flex;
        margin-right: 0
    }
    .search-btn-mobile {
        display: flex
    }
    .search-bar {
        display: none
    }
    .full-search-bar {
        display: flex;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 100;
        background: #fff;
        height: 60px;
        padding: 0rem 1.4rem
    }
    /*	.sidebar-wrapper {
		width: 260px!important;
		height: 100%;
		top: 0;
		left: -260px;
		box-shadow: none;
		z-index: 12
	}*/
    .page-content-wrapper {
        margin-left: 0
    }
    /*
	.wrapper.toggled .sidebar-wrapper {
		left: 0;
		box-shadow: 0 .3rem .6rem rgba(0,0,0,.13);
		transition: all .2s ease-out;
		z-index: 16
	}

	.wrapper.toggled .page-content-wrapper {
		margin-left: 0
	}
*/
    .wrapper.toggled .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        opacity: .6;
        z-index: 15;
        display: block;
        cursor: move;
        transition: all .2s ease-out
    }
    .footer {
        margin-left: 0
    }
    .wrapper.toggled .footer {
        margin-left: 0
    }
    /*
	.chat-header {
		border-top-left-radius: .25rem
	}

	.chat-footer {
		border-bottom-left-radius: .25rem
	}

	.chat-sidebar {
		left: -370px
	}

	.chat-content {
		margin-left: 0
	}

	.chat-header {
		left: 0
	}

	.chat-footer {
		left: 0
	}

	.chat-toggled .chat-sidebar {
		left: 0
	}

	.chat-toggled .overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 340px;
		background: #000;
		opacity: .5;
		z-index: 11;
		display: block;
		cursor: move;
		transition: all .3s ease-out
	}

	.email-header {
		border-top-left-radius: .25rem
	}

	.email-sidebar {
		left: -280px
	}

	.email-content {
		margin-left: 0
	}

	.email-header {
		left: 0
	}

	.email-toggled .email-sidebar {
		left: 0
	}

	.email-toggled .overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 250px;
		background: #000;
		opacity: .5;
		z-index: 9;
		display: block;
		cursor: move;
		transition: all .3s ease-out
	}*/
}

@media screen and (max-width:1000px) {
    .left-topbar {
        /*display: none;
		width: 0px;
		padding-left: 0px;*/
    }
    .mid-bar {
        padding: 0;
    }
    .nav-button {
        display: none;
    }
    .act-logo {
        display: none;
    }
    .login-card {
        border-right: 0 solid #dee2e6 !important;
        border-bottom: 1px solid #dee2e6 !important
    }
    .authentication-reset-password {
        height: auto;
        padding: 2.0rem 1rem
    }
    .authentication-lock-screen {
        height: auto;
        padding: 2.0rem 1rem
    }
    .error-404 {
        height: auto;
        padding: 6.0rem 1rem
    }
    .compose-mail-popup {
        width: auto;
        position: fixed;
        bottom: -30px;
        right: 0;
        left: 0
    }
    .toast-body img {
        width: 650px;
    }
}

@media screen and (max-width:796px) {
    .user-info {
        display: none
    }
    .btn-group {
        display: none;
    }
    .lang span {
        display: none
    }
    .right-topbar .navbar-nav .nav-link {
        padding: 0rem 14px;
        border-left: 0 solid #ededed;
        font-size: 25px
    }
    .page-content-wrapper {
        float: left;
        width: 100%;
        position: relative;
    }
    .resize {
        display: none
    }
    .toast-body img {
        width: 560px;
    }
    .sidebar-wrapper {
        float: left;
        width: 100%;
        /*260px*/
        height: 320px;
        position: relative;
        padding-bottom: 60px;
        /*	height: 50%;
	padding: 10px;
	position: relative;
	overflow: hidden;
	z-index: 1;
	top: 60px;
	left: 0;
	z-index: 12;
	background: #fff;
	border-right: 0 solid #d2d2d2;
	border:3px solid #ff0000;*/
        /*box-shadow: 0 .3rem .6rem rgba(0,0,0,.13);*/
        /*	transition: all .3s ease-out*/
    }
    .msg-count {
        left: 26px
    }
    .search-bar .btn-search {
        padding: 0 20px
    }
    .authentication-forgot {
        height: auto;
        padding: 2.5rem 1rem
    }
    .fc .fc-toolbar {
        display: flow-root
    }
}

@media screen and (max-width:652px) {
    .top-header .navbar .dropdown-menu::after {
        display: none
    }
    .tools {
        display: none;
    }
    .clr-button {
        display: none
    }
    .top-header .navbar .dropdown {
        position: static !important
    }
    .top-header .navbar .dropdown-menu {
        width: 100% !important
    }
    .dropdown-lg-content {
        max-height: 350px;
        overflow-y: scroll
    }
}

@media screen and (max-width:430px) {
    .logo-icon {
        display: none
    }
    .end-button {
        display: none
    }
    .chat-footer-menu,
    .chat-top-header-menu {
        display: none
    }
}