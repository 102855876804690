.congratulations-container {
    text-align: center;
    padding: 50px;
    color: #333;
    /* background: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%); */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.congratulations-container h2 {
    font-size: 3rem;
    color: #4caf50;
    margin-bottom: 15px;
}

.congratulations-container h6 {
    font-size: 1.5rem;
    margin: 20px 0;
    color: #555;
}

.congratulations-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
}

button {
    font-weight: bold;
    padding: 10px 20px;
}

footer {
    margin-top: 50px;
    font-size: 0.8rem;
    color: #666;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 15px;
}
