/* CSS file */
.drag-handle {
    cursor: move; /* This will change the cursor to a "move" cursor when hovering over the drag handle */
  }
  .question-status-drawer {
    max-height: calc(100vh - 64px); /* Adjust 64px to the height of your bottom navigation */
  }
  

/* testpages.css */

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Hide overflow to prevent main page scrollbar */
  
}

.scrollable-content {
  flex-grow: 1; /* Allow this container to grow and fill available space */
  overflow-y: auto; /* Only this container is scrollable */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

/* CSS for media within the Grid component in testpages.css */

.grid-content img,
.grid-content video,
.grid-content iframe {
  max-width: 100%; /* Ensures media does not exceed the width of the container */
  max-height: 40vh; /* Ensures media does not exceed the height of the container */
  width: auto; /* Adjusts the width automatically to maintain aspect ratio */
  height: auto; /* Adjusts the height automatically to maintain aspect ratio */
  display: block; /* Aligns the media as block, centering it horizontally */
  margin: 0 auto; /* Centers the media within the container */
}

/* Apply this class to the Grid item or container that holds the media */
.grid-content {
  overflow: hidden; /* Hides any overflowing content to maintain layout integrity */
}

/* testpages.css */
/* .flex-container,
.scrollable-content,
.grid-content{
  font-family: "Noto Serif", serif;
} */

